import React, { useEffect } from 'react';

import Layout from "../components/layout"
import Seo from "../components/seo"
 
const AdminPage = () => {
  useEffect(() => {
    window.location.href = "https://burton-trading.admin.datocms.com";
  })
  return (
  <Layout>
    <Seo title="Admin" />
  </Layout>
  )
}

export default AdminPage
 